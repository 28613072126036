import Vue from 'vue';
import LoginBox from './ui';

let $loginBox;

const showLogin = function(type) {
  if (!$loginBox) {
    const $el = document.createElement('div');
    document.body.appendChild($el);
    const Cls = Vue.extend(LoginBox);
    $loginBox = new Cls({ el: $el });
  }
  $loginBox.show(type);
};

export {
  showLogin,
};
