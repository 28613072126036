<template>
  <div id="app">
    <!-- <div class="header"> -->
    <!-- <div class="content">
        <img class="logo-header" src="@/assets/img/logo_header.png">
        <router-link class="link" to="/" :class="{ active: $route.name === 'Home' }">网站首页</router-link>
        <router-link class="link" to="/featured" :class="{ active: $route.name === 'Featured' }">好物精选</router-link>
        <router-link class="link" to="/merchants" :class="{ active: $route.name === 'Merchants' }">商家入驻</router-link>
        <router-link class="link" to="/about" :class="{ active: $route.name === 'About' }">关于我们</router-link>
        <div class="btns">
          <a href="/cart" class="cart-btn" @click.prevent="login">
            <img class="cart" src="@/assets/img/cart.png">
            购物车
          </a>
          <button class="login" @click="login">登录</button>
          <button class="register" @click="register">注册</button>
        </div>
      </div>
    </div>
    <div style="padding: 128px 0 40px;">
      <router-view/>
    </div>
    <div class="footer">
      <div class="content">
        <img class="logo-footer" src="@/assets/img/logo_footer.png">
        <div class="info-item">
          <div class="info-title">
            <img class="info-img" src="@/assets/img/contact.png">
            服务热线
          </div>
          <div class="info-content">400-820-5770</div>
        </div>
        <div class="info-item">
          <div class="info-title">
            <img class="info-img" src="@/assets/img/email.png">
            联系邮箱
          </div>
          <div class="info-content">meifuteshengwukeji@163.com</div>
        </div>
        <div class="info-item">
          <div class="info-title">
            <img class="info-img" src="@/assets/img/location.png">
            公司地址
          </div>
          <div class="info-content">上海市闵行区申长路988弄虹桥万科6号楼5楼</div>
        </div>
      </div>
      <div class="registered">上海美浮特电子商务有限公司&nbsp;
        <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备20007057号</a>
    </div>-->
    <!-- </div> -->
    <div class="home-bg" @click="goToPage"></div>
    <div class="icp-link">
      <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备20007057号</a>
    </div>
  </div>
</template>

<script>
import { showLogin } from '@/components/LoginDialog'

export default {
  methods: {
    login() {
      showLogin(0)
    },
    register() {
      showLogin(1)
    },

    goToPage() {
      window.open('https://www.meifute.com/')
    },
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}
</style>

<style scoped>
.home-bg {
  height: 100vh;
  width: 100%;
  background-image: url('../src/assets/img/home/homeBg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-attachment: fixed;
  background-position: center;
}

.icp-link {
  position: absolute;
  bottom: 0;

  left: calc(50% - 50px);
  height: 40px;
  line-height: 40px;
  text-align: center;
}
a {
  color: #fff;
}

.header {
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  background: #fff;
  border-bottom: solid 1px #d8d8d8;
  padding: 23px 0;
}
.logo-header {
  width: 370px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 70px;
}
.link {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  color: #4a4a4a;
  margin-right: 50px;
  text-decoration: none;
  padding: 6px 0;
  border-bottom: solid 2px #fff;
}
.link.active {
  color: #fd3c3c;
  border-bottom-color: #fd3c3c;
}
.btns {
  display: inline-block;
  vertical-align: middle;
  padding: 27px 0;
  padding-left: 50px;
  border-left: solid 1px #d8d8d8;
}
.cart-btn {
  display: inline-block;
  line-height: 24px;
  color: #fd3c3c;
  font-size: 14px;
  border: solid 1px;
  padding: 0 8px;
  text-decoration: none;
}
.cart {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  margin-top: -2px;
}
.login,
.register {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  line-height: 26px;
  border: none;
  outline: none;
  font-size: 14px;
  width: 50px;
  cursor: pointer;
}
.login {
  margin-left: 33px;
  background: #fd3c3c;
}
.register {
  margin-left: 1px;
  background: #4a4a4a;
}

.footer {
  background: #4a4a4a;
  color: #fff;
}
.footer > .content {
  padding: 40px 0 44px;
}
.logo-footer {
  width: 370px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 68px;
}
.registered {
  line-height: 60px;
  font-size: 14px;
  text-align: center;
  border-top: solid 1px #fff;
}
.registered a {
  color: #fff;
}
.info-item {
  display: inline-block;
  vertical-align: middle;
  margin-right: 70px;
}
.info-item:last-child {
  margin-right: 0;
}
.info-title {
  font-size: 20px;
}
.info-content {
  margin-top: 6px;
  font-size: 14px;
}
.info-img {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  margin-right: 4px;
  margin-top: -2px;
}

.header > .content,
.footer > .content {
  width: 1200px;
  margin: auto;
  white-space: nowrap;
}
</style>
