<template>
  <div class="dialog" v-show="visible">
    <div class="bg" @click="visible = false"></div>
    <div class="hacker">
    <div class="box">
      <div class="title">{{ type === 0 ? '用户登录' : '用户注册' }}</div>
      <form class="form" @submit.prevent="onSubmit">
        <!-- 账号 -->
        <div class="label">
          <span class="label-item">账号</span>
          <span class="input-err" v-show="account.err">{{ account.err }}</span>
        </div>
        <input :class="{ err: account.err }" v-model="account.value" class="input" type="text" maxlength="20">
        <!-- 身份证 -->
        <template v-if="type === 1">
          <div class="label">
            <span class="label-item">身份证</span>
            <span class="input-err" v-show="idcard.err">{{ idcard.err }}</span>
          </div>
          <input :class="{ err: idcard.err }" v-model="idcard.value" class="input" type="text" maxlength="18">
        </template>
        <!-- 手机号 -->
        <template v-if="type === 1">
          <div class="label">
            <span class="label-item">手机号</span>
            <span class="input-err" v-show="phone.err">{{ phone.err }}</span>
          </div>
          <input :class="{ err: phone.err }" v-model="phone.value" class="input" type="text" maxlength="11">
        </template>
        <!-- 验证码 -->
        <template v-if="type === 1">
          <div class="label">
            <span class="label-item">验证码</span>
            <span class="input-err" v-show="verify.err">{{ verify.err }}</span>
          </div>
          <div class="verify-input-wrapper">
            <input :class="{ err: verify.err }" v-model="verify.value" class="input verify-input" maxlength="6">
            <button class="verify-btn" type="button" @click="sendVerify">{{ countdownText }}</button>
          </div>
        </template>
        <!-- 密码 -->
        <div class="label">
          <span class="label-item">密码</span>
          <span class="input-err" v-show="password.err">{{ password.err }}</span>
        </div>
        <input :class="{ err: password.err }" v-model="password.value" class="input" type="password" maxlength="20">
        <!-- 确认密码 -->
        <template v-if="type === 1">
          <div class="label">
            <span class="label-item">确认密码</span>
            <span class="input-err" v-show="password2.err">{{ password2.err }}</span>
          </div>
          <input :class="{ err: password2.err }" v-model="password2.value" class="input" type="password" maxlength="20">
        </template>
        <br>
        <div style="overflow: hidden;clear: both;">
          <div v-if="err" class="form-err">{{ err }}</div>
          <a class="register" @click.prevent="toggleType" href="#">{{ type === 0 ? '立即注册' : '已有账号，立即登录' }}</a>
        </div>
        <button type="submit" class="submit">{{ type === 0 ? '登录' : '注册' }}</button>
      </form>
      <img class="logo" src="@/assets/img/logo_header.png">
      <img class="close" src="@/assets/img/close.png" @click="visible = false">
    </div>
    </div>
  </div>
</template>

<script>
const checkParity = function(obj) {
    obj = changeFivteenToEighteen(obj);
    var len = obj.length;
    if (len == '18') {
      var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
      var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
      var cardTemp = 0, i, valnum;
      for (i = 0; i < 17; i++) {
        cardTemp += obj.substr(i, 1) * arrInt[i];
      }
      valnum = arrCh[cardTemp % 11];
      if (valnum == obj.substr(17, 1)) {
        return true;
      }
      return false;
    }
    return false;
};
const changeFivteenToEighteen = function(obj) {
  if (obj.length == '15') {
    var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
    var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
    var cardTemp = 0, i;
    obj = obj.substr(0, 6) + '19' + obj.substr(6, obj.length - 6);
    for (i = 0; i < 17; i++) {
      cardTemp += obj.substr(i, 1) * arrInt[i];
    }
    obj += arrCh[cardTemp % 11];
    return obj;
  }
  return obj;
};
const checkIdCard = function(v) {
  if (!/(^\d{15}$)|(^\d{17}(\d|X)$)/.test(v)) return false;
  return checkParity(v);
};
const isMobile = function(v) {
  return /^1\d{10}$/.test(v);
};

export default {
  data() {
    return {
      visible: false,
      type: 0, // 0 登录 1 注册
      account: {},
      idcard: {},
      phone: {},
      verify: {},
      password: {},
      password2: {},
      err: null,
      countdown: 0,
    };
  },
  computed: {
    countdownText() {
      return this.countdown ? `重新发送（${this.countdown}）` : '发送验证码';
    },
  },
  watch: {
    visible(v) {
      document.body.style.overflow = v ? 'hidden' : 'auto';
    },
  },
  methods: {
    sendVerify() {
      if (this.verifyTimer) return;
      this.countdown = 60;
      this.verifyTimer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(this.verifyTimer);
          this.verifyTimer = null;
        }
      }, 1000);
    },
    show(type = 0) {
      this.visible = true;
      this.type = type;
      this.err = null;
      if (type === 0) {
        this.login();
      } else {
        this.register();
      }
    },
    login() {
      ['account', 'password'].forEach(key => {
        this[key] = {
          value: '',
          err: null,
        };
      });
    },
    register() {
      ['account', 'idcard', 'phone', 'verify', 'password', 'password2'].forEach(key => {
        this[key] = {
          value: '',
          err: null,
        };
      });
    },
    toggleType() {
      this.show(this.type === 0 ? 1 : 0);
    },
    onSubmit() {
      if (this.type === 0) {
        this.account.err = this.account.value ? null : '请输入账号';
        this.password.err = this.password.value ? null : '请输入密码';
        this.err = this.account.err || this.password.err ? null : '登录失败，请重试';
      } else {
        this.account.err = this.account.value ? null : '请输入账号';
        if (!this.idcard.value) {
          this.idcard.err = '请输入身份证号';
        } else if (!checkIdCard(this.idcard.value)) {
          this.idcard.err = '请输入正确的身份证号';
        } else {
          this.idcard.err = null;
        }
        if (!this.phone.value) {
          this.phone.err = '请输入手机号';
        } else if (!isMobile(this.phone.value)) {
          this.phone.err = '请输入正确的手机号';
        } else {
          this.phone.err = null;
        }
        this.verify.err = this.verify.value ? null : '请输入验证码';
        this.password.err = this.password.value ? null : '请输入密码';
        this.password2.err = this.password2.value === this.password.value ? null : '请确认密码';
        this.err = this.account.err || this.idcard.err  || this.phone.err || this.verify.err || this.password.err || this.password2.err ? null : '注册失败，请重试';
      }
    },
  },
}
</script>

<style scoped>
.dialog {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: #303030;
}
.hacker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 100px 0 100px;
  overflow: auto;
}
.bg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #303030;
  opacity: 0.5;
}
.box {
  position: relative;
  margin: auto;
  width: 900px;
  background: #FFF;
  border-radius: 10px;
  padding-bottom: 58px;
}
.title {
  text-align: center;
  font-size: 40px;
  padding-top: 69px;
  margin-bottom: 33px;
}
.form {
  width: 694px;
  margin: auto;
}
.label {
  font-size: 20px;
  margin-bottom: 11px;
}
.input {
  box-sizing: border-box;
  width: 100%;
  background: #F4F4F4;
  font-size: 22px;
  line-height: 28px;
  height: 70px;
  padding: 20px 21px;
  outline: none;
  border-radius: 3px;
  border: solid 1px #F4F4F4;
  margin-bottom: 20px;
}
.input.err {
  border-color: #FB4045;
}
.label-item {
  display: inline-block;
  width: 240px;
}
.input-err {
  color: #FB4045;
}
.form-err {
  float: left;
  color: #FB4045;
  font-size: 16px;
}
.verify-input-wrapper {
  display: flex;
}
.verify-input {
  width: auto;
  flex: 1 1;
}
.verify-btn {
  line-height: 70px;
  height: 70px;
  border-radius: 3px;
  width: 242px;
  margin-left: 16px;
  text-align: center;
  background: #397BE2;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}
.register {
  float: right;
  text-decoration: none;
  font-size: 16px;
  color: #397BE2;
  margin-top: 30px;
  margin-bottom: 44px;
}
.submit {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  line-height: 70px;
  text-align: center;
  background: #FB4045;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.logo {
  display: block;
  width: 320px;
  margin: 40px auto 0;
}
.close {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 22px;
  right: 40px;
}
</style>
