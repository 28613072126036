import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue')
  },
  // {
  //   path: '/featured',
  //   name: 'Featured',
  //   component: () => import(/* webpackChunkName: "featured" */ '../views/Featured.vue')
  // },
  // {
  //   path: '/goods/:id',
  //   name: 'GoodsDetail',
  //   component: () => import(/* webpackChunkName: "goodsDetail" */ '../views/GoodsDetail.vue')
  // },
  // {
  //   path: '/merchants',
  //   name: 'Merchants',
  //   component: () => import(/* webpackChunkName: "merchants" */ '../views/Merchants.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router